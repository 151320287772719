// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-menu-jsx": () => import("./../../../src/templates/menu.jsx" /* webpackChunkName: "component---src-templates-menu-jsx" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */),
  "component---src-templates-promotion-jsx": () => import("./../../../src/templates/promotion.jsx" /* webpackChunkName: "component---src-templates-promotion-jsx" */),
  "component---src-templates-real-weddings-post-jsx": () => import("./../../../src/templates/real-weddings-post.jsx" /* webpackChunkName: "component---src-templates-real-weddings-post-jsx" */),
  "component---src-templates-venue-jsx": () => import("./../../../src/templates/venue.jsx" /* webpackChunkName: "component---src-templates-venue-jsx" */)
}

